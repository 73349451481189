import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`The Studio`}</h1>
    <p>{`Tōsh's Studio is based in the Design District of San Francisco, serving as a recording, rehearsal, and community space for friends of the Studio Collective.`}</p>
    <img {...{
      "src": "https://res.cloudinary.com/studiocollective/image/upload/v1707368292/Studio%20Collective/Studio%20Pictures/IMG_5691_2_cyjq9n.jpg",
      "width": "100%"
    }}></img>
    <h2>{`Studio Infrastructure`}</h2>
    <p>{`The Studio is a modern recording studio focused on electronic music production and recording of live instruments. It is designed with software at it's core but with as much of the production process brought out-of-the-box into hardware as possible.`}</p>
    <p>{`Studio Infrastructure:`}</p>
    <ul>
      <li parentName="ul">{`Genelec 8361A monitors + 7360 sub`}</li>
      <li parentName="ul">{`Neve 1073OPX preamps`}</li>
      <li parentName="ul">{`Apollo x8p + x16 (32 inputs, 16 preamps)`}</li>
      <li parentName="ul">{`Console 1 mixing system w/ dedicated display`}</li>
      <li parentName="ul">{`Mac Studio with M1 Ultra`}</li>
      <li parentName="ul">{`34" 5k2k ultrawide monitor with DCI-P3 color space`}</li>
      <li parentName="ul">{`TC Electronic Stereo M Analyzer`}</li>
      <li parentName="ul">{`Secondary reference speakers (PA tops + sub, bookshelf speakers)`}</li>
      <li parentName="ul">{`2x 48 point Art patchbays`}</li>
      <li parentName="ul">{`Art 6 channel headphone distribution system`}</li>
      <li parentName="ul">{`Arturia Keylab 61 midi controller`}</li>
    </ul>
    <p>{`Studio Instruments:`}</p>
    <ul>
      <li parentName="ul">{`Yamaha NU1X hybrid piano`}</li>
      <li parentName="ul">{`Analog synths (Sub37, Polybrute)`}</li>
      <li parentName="ul">{`Semi-modular synth (Moog Matriarch)`}</li>
      <li parentName="ul">{`Evans drum set`}</li>
      <li parentName="ul">{`Analog modeled synths (Nord Wave, Nord Stage 4, JU-06A)`}</li>
      <li parentName="ul">{`Drum machines & samplers (TR-8s, Nord Drum 3P, Model:Samples)`}</li>
      <li parentName="ul">{`Guitars (St. Vincent, bass guitar)`}</li>
    </ul>
    <p>{`Mics:`}</p>
    <ul>
      <li parentName="ul">{`Neumann U87 (vocals, general)`}</li>
      <li parentName="ul">{`Neumann SKM184 (stereo pair, drum overheads, inst)`}</li>
      <li parentName="ul">{`Audix DP-5A set (drums)`}</li>
      <li parentName="ul">{`AEA R88 (stereo ribbon)`}</li>
      <li parentName="ul">{`Sennheiser MKH 8050 (hypercardiod condenser)`}</li>
      <li parentName="ul">{`Sennheisier E608 (horns)`}</li>
      <li parentName="ul">{`Sennheiser MD441-U (hypercardiod dynamic)`}</li>
      <li parentName="ul">{`Sennheiser E906 (guitar amp)`}</li>
      <li parentName="ul">{`Shure SM7B (vocal)`}</li>
      <li parentName="ul">{`AKG C414 XLII (vocal)`}</li>
      <li parentName="ul">{`Rode NT1A (vocals, general)`}</li>
      <li parentName="ul">{`Shure SM57/58s (general)`}</li>
      <li parentName="ul">{`Rode wireless pro (podcast)`}</li>
      <li parentName="ul">{`Pop filters & mic stands`}</li>
    </ul>
    <p>{`Effects:`}</p>
    <ul>
      <li parentName="ul">{`Mesa Boogie Mark V 35 amplifier`}</li>
      <li parentName="ul">{`Line 6 Helix LT`}</li>
      <li parentName="ul">{`Strymon Iridium amp/cab sim`}</li>
      <li parentName="ul">{`TC Electronic VoiceLive 3`}</li>
      <li parentName="ul">{`Effect Pedals (reverbs, delays, looper, compressors, saturators, guitar & vox effect boxes)`}</li>
      <li parentName="ul">{`VSTs (various soft synths, effects, and mastering plugins)`}</li>
    </ul>
    <p>{`Live & DJ gear:`}</p>
    <ul>
      <li parentName="ul">{`Allen & Heath Xone:96`}</li>
      <li parentName="ul">{`2x Xone K2s`}</li>
      <li parentName="ul">{`2x CDJ 2000s`}</li>
      <li parentName="ul">{`2x Technics SL-1200s`}</li>
      <li parentName="ul">{`Allen & Heath Zed 24`}</li>
      <li parentName="ul">{`Mackie DL32S`}</li>
      <li parentName="ul">{`Stage box snake`}</li>
    </ul>
    <p>{`Photography & visual art:`}</p>
    <ul>
      <li parentName="ul">{`2x BMPCC Pocket 4k + Sigma 28-70mm f2.8`}</li>
      <li parentName="ul">{`Sony a7sii + a7c`}</li>
      <li parentName="ul">{`Various fast Sony lenses`}</li>
      <li parentName="ul">{`ND + pro-mist filters`}</li>
      <li parentName="ul">{`1972 Leica CL rangefinder`}</li>
      <li parentName="ul">{`Lightform LFC kit + BenQ projector`}</li>
    </ul>
    {
      /* Additional information and gear manuals can be found [here](https://docs.google.com/spreadsheets/d/1qD9ov6ihXRuTtCci00AbqX9rQ7pLwOtJnjGLRhCDaZU/edit?usp=sharing). */
    }
    {
      /* #### Studio Revamp
      This past fall, the studio was reworked to create a more flexible environment. The key improvements will be the addition of a 32 channel USB recording mixer, two patchbays, a Console 1 mixing system, and a few semi-modular synths. The updated recording infrastructure will mimic this diagram:
      <Image src='https://res.cloudinary.com/studiocollective/image/upload/v1629204817/Studio%20Collective/studio-diagram_bnvyz3.jpg' width='100%'/>
      The patchbays will allow seamless creative routing during the recording and mixdown process as shown in the I/O guide.
      <Image src='https://res.cloudinary.com/studiocollective/image/upload/v1629204801/Studio%20Collective/inputs-outputs_t0b4ps.png'  width='100%'/> */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      